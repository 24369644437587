import React from 'react'
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const WhyChooseus = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Why Choose One Way Car?
        </h4>

        <div className="flex flex-col gap-y-[10px] pt-4">
          <p>
            <span className=' font-semibold text-xl pl-2'>One-Way Fare Only: </span>
            Pay only for the distance you travel – no return fees or hidden charges.

          </p>
          <p>
            <span className=' font-semibold text-xl pl-2'>Convenient Bookings: </span>
            Book your ride anytime, 24/7, with instant booking confirmation.
          </p>
          <p>
            <span className=' font-semibold text-xl pl-2'>Door-to-Door Service: </span>
            Enjoy the convenience of being picked up and dropped off at your desired location.

          </p>
          <p>
            <span className=' font-semibold text-xl pl-2'> Experienced Drivers:  </span>
            Our professional drivers ensure a safe and pleasant journey, guiding you seamlessly to your destination.


          </p>
        </div>
        <div className='text-3xl text-center mt-4'>Book Your Ride Today</div>
        <p className='mt-2'>Experience the convenience and reliability of our drop taxi service. Whether you’re traveling for business, leisure, or personal reasons, One Way Car is here to make your journey smooth and hassle-free.</p>

        <div className='text-3xl text-center mt-4'>How to Book One Way Cabs        </div>
        <p className='mt-2'>Booking with us is easy! Simply visit our website, select your route, and confirm your details. For any special requests or assistance, our customer support team is available 24/7. <br />
          Enjoy the freedom of one-way travel with One Way Taxi Service – book your ride today and pay only for what you need!</p>

      </div>
      {/* <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
      <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
        <div className=" flex flex-col gap-y-1">
          <img src={img1} className=" w-[80px] mx-auto" />
          <p className=" text-lg md:text-xl font-semibold">
            Easy Online Booking
          </p>
          <p>Pickup and Drop on time</p>
        </div>
        <div className=" flex flex-col gap-y-1">
          <img src={img2} className=" w-[80px] mx-auto" />
          <p className=" text-lg md:text-xl font-semibold">
          Professional Drivers
          </p>
          <p>Well Trained years of experience</p>
        </div>
        <div className=" flex flex-col gap-y-1">
          <img src={img3} className=" w-[80px] mx-auto" />
          <p className=" text-lg md:text-xl font-semibold">
          Variety of Prime Brands
          </p>
          <p>Benz - BMW - Audi</p>
        </div>
        <div className=" flex flex-col gap-y-1">
          <img src={img4} className=" w-[80px] mx-auto" />
          <p className=" text-lg md:text-xl font-semibold">
          Secure Online Payment
          </p>
          <p>24 /7 Customer Support</p>
        </div>
      </div>
    </div> */}
    </div>
  )
}

export default WhyChooseus