const telegramApi = '7737515872:AAF-uvupmbYcWkTkFnRpZYFN3A0nCqSWYf8'; // Replace with your actual backend URL
const telegramChatId = 7609085440;
const netbixTelegramApi = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'; // Replace with your actual backend URL
const netbixTelegramChatId = 1113684027;



// mobile number
const customerMobileNumber = 7358283819;
const front_url = "https://www.onewaycar.co.in/";
export { telegramApi, telegramChatId, netbixTelegramApi, netbixTelegramChatId, customerMobileNumber, front_url };