import React from 'react'

const Vision = () => {
  return (
    <div className='py-5 mx-auto py-20 px-4 md:px-8 lg:px-16 bg-[#ff3131] flex flex-col items-center gap-5 relative'>
      <div>Book Now</div>
      <h1 className='text-center text-3xl md:text-5xl lg:text-5xl font-semibold'>Save <span class="text-white">
        Time & Cost </span> with the <br />
        One Way Car</h1>
      <i className='text-xl'>Explore the Journey </i>
      <a href="#book_taxi_form" className='th-btn-reverse'>Book Now</a>


    </div>
  )
}

export default Vision