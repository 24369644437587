import React from 'react'
import about from "../assets/about.jpg";
import { FaPhone } from 'react-icons/fa6';
const About = () => {
    return (
        <div id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-5 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[500px] car_outline' />
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>
                        <div className='text-3xl md:text-4xl font-bold mt-4'>Welcome to One Way Taxi Service – Affordable, Convenient, and Reliable
                        </div>
                        <p className='text-black opacity-60 md:text-lg mt-5'>
                            Looking for a hassle-free one-way taxi ride across Tamil Nadu, Bangalore, and Pondicherry? You've come to the right place! At One Way Taxi Service, we pride ourselves on delivering safe, reliable, and cost-effective travel options to get you to your destination without the worry of a return fare.


                        </p>
                        <div className=' mt-5'>
                            <a
                                href="tel:+917358283819"
                                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-3 py-2"
                            >
                                <span className=" flex gap-x-2 items-center justify-center">
                                    <FaPhone size={17} /> +91 7358283819
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About