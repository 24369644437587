import React from 'react'

const PricePlanComponents = ({ place, image, oneway, roundtrip }) => {
  return (
    <div className=" py-6 px-4 rounded-lg shadow-lg border  bg-white h-full">
      <div className=' flex flex-col justify-between flex-1 h-full'>
        <p className=" font-bold">{place}</p>
        <img src={image} className=" w-[200px] mx-auto" />
        <div className=" flex gap-x-2 justify-between mt-3 pb-3  border-b flex-1">
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
            <span className="flex gap-x-1 font-bold align-baseline">
              <span className=" primary-color text-xl">Rs</span>
              <span className=" text-3xl ">{oneway}</span>
            </span>
            <span className=" opacity-50 font-semibold">One Way</span>
          </div>
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
            <span className="flex gap-x-1 font-semibold align-baseline">
              <span className=" primary-color text-xl">Rs</span>
              <span className=" text-3xl">{roundtrip}</span>
            </span>
            <span className=" opacity-50 font-semibold">Round Trip</span>
          </div>
        </div>


        <div className=" flex flex-row gap-x-2 justify-between mt-3 pb-3  border-b ">
          <div className=" flex flex-col gap-y-2  items-center">
            <a href="#book_taxi_form" className="th-btn w-full">Book Now</a>
          </div>
          <div className=" flex flex-col gap-y-2  items-center">
            <a href="tel:+917358283819" className="th-btn w-full">7358283819</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricePlanComponents
