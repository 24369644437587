import React from "react";
import OurServiceComponent from "./OurServiceComponent";
import train from "../../assets/images/trainicon.png";
import aeroplane from "../../assets/images/aeroplaneicon.png";
import taxi from "../../assets/images/taxiicon.png";
import tour from "../../assets/images/tour-guide-icon.png";

const OurServices = () => {
  return (

    <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16" id='service'>
      <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Our Service
      </h4>
      <p className="mt-2 text-center">At One Way Car, we offer a wide range of drop taxi services to cater to your travel needs across Tamil Nadu. Whether you're planning a short trip or a long journey, we've got you covered.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 my-12">
        <OurServiceComponent img={taxi} heading='One-Way Drop Taxi: ' para="Perfect for one-way journeys, saving you money by only charging for the kilometers travelled.
" />
        <OurServiceComponent img={tour} heading='Airport Transfers: ' para='Reliable, timely pickups and drop-offs at airports across Tamil Nadu, Bangalore, and Pondicherry.
' />
        <OurServiceComponent img={aeroplane} heading='Outstation Trips: ' para='Affordable rides to your favourite destinations without the hassle of a round-trip fare.
' />
        {/* <OurServiceComponent img={train} heading='Long-Distance Travel' para='Planning a trip to a distant location? Our long-distance travel service is ideal for those seeking comfort and convenience on extended journeys. We cover all major routes across Tamil Nadu, ensuring a safe and enjoyable ride.' /> */}
      </div>
    </div>
  );
};

export default OurServices;
